import React, { useContext, useEffect, useState } from 'react';
import { useSprings, animated, config } from 'react-spring';

import { Navbar } from '../Components/Navbar';
import { Footer } from '../Components/Footer';

import { Check } from 'react-feather';

import { TransitionContext } from '../Context/TransitionContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './FAQ.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from '../Context/AuthContext';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';

const FAQ = ({ windowSize }) => {

  const { setColor } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();
  
  const { signedIn } = useContext(AuthContext);

  useEffect(() => {
    setColor(true);
  }, [setColor]);

  const [faqValues, setFaqValues] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false ]);

  const faqSprings = useSprings(faqValues.length, faqValues.map(value => ({ maxHeight: value ? (windowSize.width > 1024 ? 'calc(60vw)' : windowSize.width > 767 ? 'calc(130vw)' : 'calc(350vw)') : (windowSize.width > 1024 ? 'calc(3.8vw)' : (windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 'calc(6vw)' : 'calc(4vw)') : (windowSize.orientation === 'portrait' ? 'calc(20vw)' : 'calc(4vw)'))), config: value ? config.molasses : config.default })));

  const toggleLine = index => {
    setFaqValues( faqValues.map((value, mIndex) => mIndex === index ? !value : false) );
  }
  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  return (
    <>
       <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
       <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>
    <TopBar/>
    <div className="homeContainer">
    <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
      <div className="faqHeader">
        <h1 className="heading">Frequently Asked Questions</h1>
        <h2 className="faqSubheading">In this section, we answer some frequent questions related to our services. If you have any further questions, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact our support team.</span></h2>
      </div>
      <div className="faqGeneral">
        <h2 className="documentsHeading">General Questions</h2>
        <animated.div style={faqSprings[1]} className="certifiedFaqLine" onClick={() => toggleLine(1)}>
          <p className="certifiedFaqLineTitle">Who will translate my documents?</p>
          <p className="certifiedFaqLineText">We only use 100% human translation carried out by professional translators. Our translators are based all over the world and typically have a minimum of 5 years of experience as professional translators. Many carry a variety of accreditations and certifications, but this varies per translator as there is no standard worldwide accreditation or certification for translators.</p>
        </animated.div>
        <animated.div style={faqSprings[2]} className="certifiedFaqLine" onClick={() => toggleLine(2)}>
          <p className="certifiedFaqLineTitle">How long does translation take?</p>
          <p className="certifiedFaqLineText">Turnaround time depends on many factors including the word count, language pair, current demand, translator availability and complexity of the source document to name a few. In general, 1-3 page documents (up to 750 words) are delivered digitally in 24 hours or less. Expedited turnaround is available to reduce the turnaround time by approximately 50%. We will show an estimated turnaround time in the order page before you place it.</p>
        </animated.div>
        <animated.div style={faqSprings[3]} className="certifiedFaqLine" onClick={() => toggleLine(3)}>
          <p className="certifiedFaqLineTitle">What languages do you translate?</p>
          <p className="certifiedFaqLineText">Please review the full list of our <span className="hoveringTextLink" onClick={(e) => transitionTo('/languages',e)}>supported languages</span>. If you require a language we do not have listed please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span>, we can likely still assist you.</p>
        </animated.div>
        <animated.div style={faqSprings[4]} className="certifiedFaqLine" onClick={() => toggleLine(4)}>
          <p className="certifiedFaqLineTitle">What documents do you translate?</p>
          <p className="certifiedFaqLineText">We can translate nearly any legible document in almost any format. If you are unsure if your document is translatable, please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact our support team</span>.</p>
        </animated.div>
        <animated.div style={faqSprings[5]} className="certifiedFaqLine" onClick={() => toggleLine(5)}>
          <p className="certifiedFaqLineTitle">Will your translation be accepted?</p>
          <p className="certifiedFaqLineText">
            Our translations are generally accepted by governments, courts, universities, etc. worldwide, but it is your responsibility to ensure our translation will meet the specific requirements of the receiving office. If you aren't sure, <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> with a list of their requirements and we can review with you.
            <br/><br/>
            We regularly perform translations for U.S. Citizenship and Immigration Services (USCIS) and are intimately familiar with their requirements. Therefore we guarantee acceptance of our certified translations by USCIS.</p>
        </animated.div>
        <animated.div style={faqSprings[6]} className="certifiedFaqLine" onClick={() => toggleLine(6)}>
          <p className="certifiedFaqLineTitle">Can I supply notes to my translator?</p>
          <p className="certifiedFaqLineText">Yes, during checkout you will find a notes field where you can enter any notes you'd like our staff or your translator to have. Proper spelling of names and places is requested as not all are directly translatable.</p>
        </animated.div>
        <animated.div style={faqSprings[7]} className="certifiedFaqLine" onClick={() => toggleLine(7)}>
          <p className="certifiedFaqLineTitle">How do you keep my information secure?</p>
          <p className="certifiedFaqLineText">
            Your information, including files, is transmitted through a secure channel with SSL/TLS encryption, which is the current standard for encryption on the web. Also:
            <br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>We do not store your credit card information on our servers. It is only handled and stored in a PCI compliant environment by our processing partner PayPal.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>We will never share your files or personal information with anyone outside of eVerbary.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Files are only available to essential staff with signed NDAs.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Files are encrypted during transfer and while being stored with the Advanced Encryption Standard 256 bits (AES-256). <br/><br/>
          </p>
        </animated.div>
        <animated.div style={faqSprings[8]} className="certifiedFaqLine" onClick={() => toggleLine(8)}>
          <p className="certifiedFaqLineTitle">What are your payment terms and methods?</p>
          <p className="certifiedFaqLineText">
            We accept credit cards, debit cards, wire transfers, checks and PayPal. Should you need any assistance for processing the payment, please contact us at <span className="hoveringTextLink" onClick={() => window.open('mailto:acounting@everbary.com', '_blank')}>support@everbary.com</span>
            <br/><br/>
            Payment is requested in advance. You can see our Refund Policy <span className="hoveringTextLink" onClick={(e) => transitionTo('/refund',e)}>here</span>.
          </p>
        </animated.div>
      </div>
      <div className="faqGeneral">
        <h3 className="documentsHeading">Certified Translations</h3>
        <animated.div style={faqSprings[9]} className="certifiedFaqLine" onClick={() => toggleLine(9)}>
          <p className="certifiedFaqLineTitle">What is a certified translation?</p>
          <p className="certifiedFaqLineText">A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments. Learn more about <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translations</span>.</p>
        </animated.div>
        <animated.div style={faqSprings[10]} className="certifiedFaqLine" onClick={() => toggleLine(10)}>
          <p className="certifiedFaqLineTitle">What will you deliver to me?</p>
          <p className="certifiedFaqLineText">
            Certified translations are delivered as a non-editable PDF file and include a signed and stamped English language Certificate of Translation Accuracy, which attests to the thoroughness and accuracy of the translation and the qualifications of the translator. In addition, our certified translations:
            <br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Are on company letterhead containing our ATA member number, phone number, and address.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Include the order number and page number on each page.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Are formatted similar to, but not exactly like, the source document.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Are word-for-word translations of the source, which means some phrases might not be ideal, but we cannot interpret the meaning of a phrase, only provide an exact translation per certified translation standards.<br/><br/>
            <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Do not include a copy of your source files in the same PDF unless specifically requested.
            <br/><br/>
            If you order a physical copy we will ship you an unfolded copy of the translation with wet ink signatures in a cardboard USPS envelope printed on 24 lb. paper via a color laser printer. We do not print and ship a copy of your source documents as you will pair our translation with the originals already in your possession.</p>
        </animated.div>
        <animated.div style={faqSprings[11]} className="certifiedFaqLine" onClick={() => toggleLine(11)}>
          <p className="certifiedFaqLineTitle">Do you offer expedited turnaround?</p>
          <p className="certifiedFaqLineText">Expedited turnaround is available for an additional fee and will reduce turnaround time by approximately 50%. Expedited orders are flagged as high-priority, moved to the front of the queue, and extended hours are worked to ensure the quickest turnaround possible.</p>
        </animated.div>
        <animated.div style={faqSprings[12]} className="certifiedFaqLine" onClick={() => toggleLine(12)}>
          <p className="certifiedFaqLineTitle">Do you offer notarization?</p>
          <p className="certifiedFaqLineText">
          We offer notarization as an option on all certified translations for an additional fee. Notarization includes a signature from either a representative of our company or a translator and a Notary Public along with a rubber ink stamp from the Notary with their commission number and commission expiration date. The notarization is valid in all 50 states.
          </p>
          </animated.div>
        <animated.div style={faqSprings[13]} className="certifiedFaqLine" onClick={() => toggleLine(13)}>
          <p className="certifiedFaqLineTitle">Can you supply an apostille for my notarized translation?</p>
          <p className="certifiedFaqLineText">We do not supply an apostille for your notarized translation, but you can obtain one from the Secretary of State. We kindly ask you to let us know in advance if you will need an apostille.</p>
        </animated.div>
        <animated.div style={faqSprings[14]} className="certifiedFaqLine" onClick={() => toggleLine(14)}>
          <p className="certifiedFaqLineTitle">Can you send me a physical copy?</p>
          <p className="certifiedFaqLineText">
            We offer USPS Priority and Express Mail services with tracking. Pricing and transit times vary by destination and are listed during <span className="hoveringTextLink" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)}>checkout</span> or you may <span className="hoveringTextLink" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>request a quote</span>.
            <br/><br/>
            If you order a physical copy we will ship you an unfolded copy of the translation with wet ink signatures in a cardboard USPS envelope printed on 24 lb. paper via a color laser printer. We do not print and ship a copy of your source documents as you will pair our translation with the originals already in your possession.
            <br/><br/>
            We will notify you via email when your translation is ready for review. We request your approval prior to shipping and will hold your shipment for up to one week waiting for your response. Once approved and/or shipped we will only be able to apply revisions to the digital copy unless you'd like to pay for another physical copy to be shipped.
        </p>
        </animated.div>
        <animated.div style={faqSprings[15]} className="certifiedFaqLine" onClick={() => toggleLine(15)}>
          <p className="certifiedFaqLineTitle">Do you offer currrency conversion?</p>
          <p className="certifiedFaqLineText">Currency conversion is not included with our certified translations. We do offer currency conversion on a case-by-case basis and can provide you with a <span className="hoveringTextLink" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>quote</span> for the service. You will have to supply the exchange rate, and we will note it on the Certificate of Translation Accuracy.</p>
        </animated.div>
        <animated.div style={faqSprings[16]} className="certifiedFaqLine" onClick={() => toggleLine(16)}>
          <p className="certifiedFaqLineTitle">Do you evaluate foreign credentials?</p>
          <p className="certifiedFaqLineText">No, credential evaluation is a different discipline and is not performed by a translator. We can provide a certified translation of your academic documents, which you will then submit along with your source documents to a specialist to evaluate the credentials to find the equivalences in your target country.</p>
        </animated.div>
        <animated.div style={faqSprings[17]} className="certifiedFaqLine" onClick={() => toggleLine(17)}>
          <p className="certifiedFaqLineTitle">Can you fill out forms for me?</p>
          <p className="certifiedFaqLineText">We typically do not fill out forms for you, but this is determined on a case-by-case basis. <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>Contact us</span> before placing your order with the specifics of your request and we will let you know if we are able to assist.</p>
        </animated.div>
        <animated.div style={faqSprings[18]} className="certifiedFaqLine" onClick={() => toggleLine(18)}>
          <p className="certifiedFaqLineTitle">Do I need to submit original documents to you?</p>
          <p className="certifiedFaqLineText">No. An original copy of your document is not necessary. A scanned copy via email, fax, or photograph is sufficient.</p>
        </animated.div>
        <animated.div style={faqSprings[19]} className="certifiedFaqLine" onClick={() => toggleLine(19)}>
          <p className="certifiedFaqLineTitle">What's the difference between a certified and notarized translation?</p>
          <p className="certifiedFaqLineText">A certified translation is a document translated by a professional translator and accompanied by a signed Certificate of Accuracy. A notarized translation bears a notary’s seal and is an additional service that involves adding the signature of a notary to your document. You can always <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> if you’re not sure what type of document you need.</p>
        </animated.div>
        <animated.div style={faqSprings[20]} className="certifiedFaqLine" onClick={() => toggleLine(20)}>
          <p className="certifiedFaqLineTitle">What is a "sworn translator" and do you have any available?</p>
          <p className="certifiedFaqLineText">A sworn translator is a translator who has been certified by the government of his or her residence and can directly stamp their translations as accurate and faithful to the original document. The system in the US and many other countries works differently and we combine our agency certification with a notary’s signature to achieve the equivalent standard.</p>
        </animated.div>
      </div>
      <div className="faqGeneral">
        <h3 className="documentsHeading">Standard Translations</h3>
        <animated.div style={faqSprings[21]} className="certifiedFaqLine" onClick={() => toggleLine(21)}>
          <p className="certifiedFaqLineTitle">What is a standard translation?</p>
          <p className="certifiedFaqLineText">A human translation of documents or text-based content delivered in an editable format for business or personal use. Perfect for legal documents, employee manuals, business communications, blog posts, product descriptions, and many other documents and types of content. Learn more about <span className="hoveringTextLink" onClick={(e) => transitionTo('/standard',e)}>standard translations</span>.</p>
        </animated.div>
        <animated.div style={faqSprings[22]} className="certifiedFaqLine" onClick={() => toggleLine(22)}>
          <p className="certifiedFaqLineTitle">What will you deliver to me?</p>
          <p className="certifiedFaqLineText">Standard translations are delivered as an editable Microsoft Word document (.docx). If you need another format such as .pages, .txt, .rtf, etc. we can likely accommodate. Standard translations only include very basic formatting. If you have complex formatting or need the translation to be completed in the document you supply please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> and include a detailed description of your needs.</p>
        </animated.div>
        <animated.div style={faqSprings[23]} className="certifiedFaqLine" onClick={() => toggleLine(23)}>
          <p className="certifiedFaqLineTitle">Do you offer expedited turnaround?</p>
          <p className="certifiedFaqLineText">Expedited turnaround is available for an additional fee and will reduce turnaround time by approximately 50%. Expedited orders are flagged as high-priority, moved to the front of the queue, and extended hours are worked to ensure the quickest turnaround possible.</p>
        </animated.div>
      </div>
      <div className="faqGeneral">
        <h3 className="documentsHeading">About eVerbary</h3>
        <animated.div style={faqSprings[24]} className="certifiedFaqLine" onClick={() => toggleLine(24)}>
          <p className="certifiedFaqLineTitle">Can I read reviews or check references?</p>
          <p className="certifiedFaqLineText">
            You can read <span className="hoveringTextLink" onClick={(e) => transitionTo('/reviews',e)}>thousands of reviews</span> online directly from verified clients to hear first hand how we consistently live up to our promise of quick turnaround times and great quality.
            <br/><br/>
            Be aware that this is the only place online to read real reviews directly from our clients. We are aware of multiple fraudulent sites with fake reviews setup to dissuade clients from using us and several other top translation service suppliers.</p>
        </animated.div>
        <animated.div style={faqSprings[25]} className="certifiedFaqLine" onClick={() => toggleLine(25)}>
          <p className="certifiedFaqLineTitle">Is eVerbary an ATA member?</p>
          <p className="certifiedFaqLineText">
            We are a corporate member of the American Translators Association (ATA).
            <br/><br/>
            There is no worldwide standard for individual translator certification. Our translators typically maintain credentials based on the country they live in, but it can vary per individual. If you have a specific requirement please contact us prior to ordering.</p>
        </animated.div>
        <animated.div style={faqSprings[26]} className="certifiedFaqLine" onClick={() => toggleLine(26)}>
          <p className="certifiedFaqLineTitle">Can I send or drop off my documents?</p>
          <p className="certifiedFaqLineText">We do not accept physical documents either in person or via mail. We only accept digital files via WhatsApp or uploaded through this website's secure uploader for quotes and orders. These are the safest and most efficient ways to handle your documents.</p>
        </animated.div>
        <animated.div style={faqSprings[27]} className="certifiedFaqLine" onClick={() => toggleLine(27)}>
          <p className="certifiedFaqLineTitle">Do you offer phone support?</p>
          <p className="certifiedFaqLineText">We exclusively offer email support to ensure accuracy and efficiency in supporting your translation needs. We found that phone support was too prone to miscommunication when dealing with the wide variety of documents and languages we support.</p>
        </animated.div>
      </div>
      <div className="faqGeneral">
        <h3 className="documentsHeading">Other Questions</h3>
        <animated.div style={faqSprings[28]} className="certifiedFaqLine" onClick={() => toggleLine(28)}>
          <p className="certifiedFaqLineTitle">What is the status of my order?</p>
          <p className="certifiedFaqLineText">You can check the status of your order anytime by visiting your order status page. A link to your order status page can be found in the confirmation email and other order related communications.</p>
        </animated.div>
        <animated.div style={faqSprings[29]} className="certifiedFaqLine" onClick={() => toggleLine(29)}>
          <p className="certifiedFaqLineTitle">How do I modify or cancel my order?</p>
          <p className="certifiedFaqLineText">
            We will do everything possible to accommodate your modification request, but these will be handled on a case-by-case basis.
            <br/><br/>
            Cancellation requests will also be handled on a case-by-case basis and any work completed cannot be canceled. If possible we will have the translator stop the project and we will issue a pro-rated refund for work not completed. Smaller orders are typically not able to be canceled once they have been accepted by a translator.
            <br/><br/>
            Please <span className="hoveringTextLink" onClick={(e) => transitionTo('/contact',e)}>contact us</span> immediately with your modification or cancellation request.</p>
        </animated.div>
        <animated.div style={faqSprings[30]} className="certifiedFaqLine" onClick={() => toggleLine(30)}>
          <p className="certifiedFaqLineTitle">How do I request a revision?</p>
          <p className="certifiedFaqLineText">You can request a revision directly from your order status page. We provide a link to your order status page in the order confirmation email and in most other communications.</p>
        </animated.div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default FAQ;