import React from 'react';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import './AccountFooter.css';

const AccountFooter = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();

  return (
    <div className="accountFooterContainer">
      <div className="footerMainContainer">
        <div className="footerMainSecondary">
          <img src={require('../assets/altLogo.png')} alt="Small logo eVerbary" className="footerMainSecondaryLogo" />
          <p className="footerSecondaryText" style={{ color: '#18b7bb' }}>2023 © eVerbary</p>
          <p className="footerSecondaryText" style={{ color: '#18b7bb' }}><span className="hoveringTextLink" onClick={(e) => transitionTo('/terms', e)}>Terms</span> - <span className="hoveringTextLink" onClick={(e) => transitionTo('/refunds', e)}>Refunds</span> - <span className="hoveringTextLink" onClick={(e) => transitionTo('/privacy', e)}>Privacy</span></p>
        </div>
        <div className="footerMainListContainer">
          <div className="footerMainListColumn">
            <p className="footerMainListColumnTitle" style={{ color: '#18b7bb' }}>Services</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/certified', e)}>Certified Translation</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/standard', e)}>Standard Translation</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/interpretations', e)}>Interpretations</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/business', e)}>For Businesses</p>
          </div>
          <div className="footerMainListColumn nonMobileColumn">
            <p className="footerMainListColumnTitle" style={{ color: '#18b7bb' }}>Resources</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/languages', e)}>Languages</p>
            {/* <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/documents',e)}>Documents</p> */}
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/use-cases', e)}>Use Cases</p>
          </div>
          <div className="footerMainListColumn">
            <p className="footerMainListColumnTitle" style={{ color: '#18b7bb' }}>Help</p>
            {/* <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/guides', e)}>Guides</p> */}
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/faq',e)}>FAQ</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/contact',e)}>Contact</p>
          </div>
          <div className="footerMainListColumn">
            <p className="footerMainListColumnTitle" style={{ color: '#18b7bb' }}>Company</p>
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/about', e)}>About</p>
            {/* <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/jobs',e)}>Jobs</p> */}
            <p className="footerMainListColumnLink" style={{ color: '#18b7bb' }} onClick={(e) => transitionTo('/reviews', e)}>Reviews</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AccountFooter };