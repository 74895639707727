import React, { useState, useContext, useEffect } from 'react';
import { useSprings, animated, config } from 'react-spring';

import { Check } from 'react-feather';

import { Navbar } from '../Components/Navbar';
import { AltAnimatedButton } from '../Components/AltAnimatedButton';
import { AnimatedButton } from '../Components/AnimatedButton';
import { UseCasesDisplay } from '../Components/UseCasesDisplay';
import { DocumentsDisplay } from '../Components/DocumentsDisplay';
import { LanguagesDisplay } from '../Components/LanguagesDisplay';
import { Footer } from '../Components/Footer';

import { TransitionContext } from '../Context/TransitionContext';

import { useScreenTransition } from '../Hooks/useScreenTransition';


import './Certified.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from '../Context/AuthContext';
import TopBar from '../Components/TopBar';
import SignInForm from './SignIn/SignInForm';
import CreateAccount from './CreateAccount/CreateAccount';

const Certified = ({ windowSize }) => {

  const { setColor, webP } = useContext(TransitionContext);

  const { transitionTo } = useScreenTransition();
  const { signedIn } = useContext(AuthContext);

  useEffect(() => {
    setColor(false);
  }, [setColor]);

  const [faqValues, setFaqValues] = useState([false, false, false, false, false, false, false]);

  const faqSprings = useSprings(faqValues.length, faqValues.map(value => ({ maxHeight: value ? (windowSize.width > 1024 ? 'calc(60vw)' : windowSize.width > 767 ? 'calc(130vw)' : 'calc(350vw)') : (windowSize.width > 1024 ? 'calc(3.8vw)' : (windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 'calc(6vw)' : 'calc(4vw)') : (windowSize.orientation === 'portrait' ? 'calc(20vw)' : 'calc(4vw)'))), config: value ? config.molasses : config.default })));

  const toggleLine = index => {
    setFaqValues( faqValues.map((value, mIndex) => mIndex === index ? !value : false) );
  }
 
  const [isModalOpen, setIsModalOpen]=useState(false);
  const [isCreateAccountOpen, setIsCreateAccountOpen]=useState(false);


  const handleLoginOpen=(newState)=>{
    setIsModalOpen(newState);
  }

  const handleCreateAccountOpen=(newState)=>{
    setIsCreateAccountOpen(newState);
  }

  return (
    <>
    <SignInForm open={isModalOpen} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
    <CreateAccount open={isCreateAccountOpen} isCreateAccountOpen={handleCreateAccountOpen} isLoginFormOpen={handleLoginOpen}/>

    <TopBar/>
    <div className="homeContainer">
      { webP ? <img src={require(`../assets/${webP === 'webp' ? 'headerBg.webp' : 'headerBg.png'}`)} alt="Decorative Heading Background" className="homeHeaderBg" /> : null}
      <Navbar windowSize={windowSize} isLoginFormOpen={handleLoginOpen} isCreateAccountOpen={handleCreateAccountOpen}/>
      <div className="headerContainer">
        <div className="headerInfoContainer">
          <h1 className="heading">Certified Translation Services</h1>
          <h2 className="subheading">Get your documents translated and certified by a professional translator in 60+ languages for just $24.95 per page with 24 hour delivery.</h2>
          <p className="subheadingFootnote"></p>
          <AltAnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.6) : (windowSize.orientation === 'portrait' ? 6 : 2.2)} />
        </div>
        { webP ? <img src={require(`../assets/${webP === 'webp' ? 'certified.webp' : 'certified.png'}`)} alt="People stamping a translation" className="certifiedHeaderImg" /> : null}
      </div>
      <div className="certificationContainer">
        <div className="certificationItemContainer">
          <img src={require('../assets/ata-logo.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">ATA Corporate Member #273062</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-3.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">100% guaranteed acceptance by USCIS</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-1.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Trusted by +350 law firms</p>
        </div>
        <div className="certificationItemContainer">
          <img src={require('../assets/icon-2.png')} alt="Logo ATA" className="certificationLogo" />
          <p className="certificationText">Same-day delivery available</p>
        </div>
      </div>
      <div className="certifiedMainInfoContainer">
        <div className="certifiedMainInfoTextContainer">
          <h2 className="certifiedMainInfoTitle">Certified Translation</h2>
          <p className="certifiedMainInfoText">Certified translations are for official uses where your receiver requires certification to verify that the translation is complete and accurate. This type of translation is commonly required for submission to U.S. Immigration (USCIS), universities, courts and many local, state, and federal governments.
            <br/><br/>
            The translation must be a literal (word-for-word) translation of all visible text to satisfy the complete requirement. Translators cannot add/remove text or interpret the meaning behind the text. For vital documents such as birth certificates that requirement doesn't typically prove to be noticeable, but for other types of documents that contain full sentences with more nuance it can undoubtedly be. This requirement is vital for your receiver so that they can have confidence that the translation exactly represents the original source material.
            <br/><br/>
            The translation certification must attest to the fact that the translation was performed by a qualified translator fluent in the language pair to satisfy the accurate requirement. We only use professional human translators and 100% of our workforce meets this requirement. An authorized signer attests to this fact on the certification. We also include free revisions to ensure the final translation is 100% accurate.
            <br/><br/>
            We take additional steps when preparing your translation packet to ensure acceptance by the widest range of receivers. We provide all certified translations on company letterhead with order and page numbers across all pages along with our contact info and ATA corporate member number. Our certification also specifies that the translator has no relation, personal or otherwise, to the owner of the document and when needed we can have the certification notarized to authenticate the identity of the authorized signer and provide you with the original copies including wet ink signatures.
            <br/><br/>
            eVerbary is among the largest providers of certified translations in the United States. Our services are utilized by tens of thousands businesses and individuals every year because we consistently deliver high quality translations quickly and affordably.</p>
        </div>
        <div className="certifiedMainInfoBlockContainer">
          <div className="certifiedMainInfoBlockHeader">
            <h3 className="certifiedMainInfoBlockHeaderTitle">Certified Translation</h3>
            <p className="certifiedMainInfoBlockHeaderPrice">$24.95</p>
            <p className="certifiedMainInfoBlockHeaderMini">per page</p>
          </div>
          <div className="certifiedMainInfoBlockMain">
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Signed and stamped certification</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Translated by a professional</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Delivery starts at 24 hours</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Up to 250 words per page</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Revisions included</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Digital delivery included</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">USCIS acceptance guaranteed</p>
            </div>
            <div className="certifiedMainInfoBlockLine">
              <Check size={windowSize.width <= 1024 ? 15 : 20} color='#18b7bb' />
              <p className="certifiedMainInfoBlockLineText">Notarization, shipping, and expedited turnaround available</p>
            </div>
            <div className="servicesDisplayButtonsContainer">
              <AnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.4) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
              <p className="servicesDisplayButtonsMore" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/quote/details' : '/quote/contact'}`,e)}>Request a Quote</p>
            </div>
          </div>
        </div>
      </div>
      <div className="useContainer certifiedSampleContainer">
        <div className="useCaseInfoContainer certifiedSampleInfoContainer">
          <h3 className="useCaseInfoTitle">Sample Certified Translations</h3>
          <p className="useCaseInfoSub">The digital copy of your translation will be delivered as a PDF and have a similar format to your source document, but we don’t try to replicate the design.</p>
        </div>
        <div className="useCaseBlock certifiedSampleBlock">
          <img onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/MarriageCertificate.pdf?alt=media&token=048abb8c-2b22-4225-8170-a58645a62b8e', '_blank')} src={require('../assets/MarriageCertificate_Screen.jpg')} alt="Sample Marriage Certificate" className="useCaseImgContainer certifiedSampleBlockImgContainer" />
          <p className="useCaseBlockText">Sample<br/>Certification</p>
        </div>
        <div className="useCaseBlock certifiedSampleBlock">
          <img onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/BirthCertificate.pdf?alt=media&token=85a8b12f-1d62-4627-95c0-6119205c9a4a', '_blank')} src={require('../assets/BirthCertificate_Screen.jpg')} alt="Birth Certificate" className="useCaseImgContainer certifiedSampleBlockImgContainer" />
          <p className="useCaseBlockText">Sample<br/>Birth Certificate</p>
        </div>
        <div className="useCaseBlock certifiedSampleBlock nonMobileBlock">
          <img onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/AcademicTranscripts.pdf?alt=media&token=f2ecc7bc-30d6-41d6-a9bc-b2358adfd309', '_blank')} src={require('../assets/AcademicTranscripts_Screen.jpg')} alt="Academic Transcripts" className="useCaseImgContainer certifiedSampleBlockImgContainer" />
          <p className="useCaseBlockText">Sample<br/>Academic Transcripts</p>
        </div>
        <div className="useCaseBlock certifiedSampleBlock nonMobileBlock">
          <img onClick={() => window.open('https://firebasestorage.googleapis.com/v0/b/project-everbary.appspot.com/o/BankStatement.pdf?alt=media&token=ea7541f0-554c-4394-a99a-ca3eceb23a1d', '_blank')} src={require('../assets/BankStatement_Screen.jpg')} alt="Bank Statement" className="useCaseImgContainer certifiedSampleBlockImgContainer" />
          <p className="useCaseBlockText">Sample<br/>Bank Statement</p>
        </div>
      </div>
      <UseCasesDisplay />
      <DocumentsDisplay />
      <LanguagesDisplay />
      <div className="certifiedFaqSection">
        <div className="certifiedFaqs">
          <h3 className="certifiedFaqTitle">Frequently Asked Questions</h3>
          <animated.div style={faqSprings[9]} className="certifiedFaqLine" onClick={() => toggleLine(9)}>
          <p className="certifiedFaqLineTitle">What is a certified translation?</p>
          <p className="certifiedFaqLineText">A word-for-word human translation of documents delivered on eVerbary letterhead including a signed and stamped Certificate of Translation Accuracy for official use as required by U.S. Immigration (USCIS), universities, courts, and many local, state, and federal governments. Learn more about <span className="hoveringTextLink" onClick={(e) => transitionTo('/certified',e)}>certified translations</span>.</p>
        </animated.div>
          <animated.div style={faqSprings[1]} className="certifiedFaqLine" onClick={() => toggleLine(1)}>
            <p className="certifiedFaqLineTitle">Can I certify my own translation?</p>
            <p className="certifiedFaqLineText">Typically you cannot certify your own translation, but it depends on the specific requirements of your receiver. You should always verify your receiver's requirements before ordering a translation to ensure you make the correct selections.</p>
          </animated.div>
          <animated.div style={faqSprings[2]} className="certifiedFaqLine" onClick={() => toggleLine(2)}>
            <p className="certifiedFaqLineTitle">What is the difference between notarized and certified?</p>
            <p className="certifiedFaqLineText">
              The translation certification attests to the thoroughness and accuracy of the translation and the qualifications of the translator. It is signed either by the translator or an authorized signer from the translation agency representing the translator.
              <br/><br/>
              Notarization authenticates the identity of the signer of the certification. This adds an additional layer of validation to the translation and the receiver can be confident that the certification is authentic.
               </p>
          </animated.div>
          <animated.div style={faqSprings[3]} className="certifiedFaqLine" onClick={() => toggleLine(3)}>
            <p className="certifiedFaqLineTitle">What will you deliver to me?</p>
            <p className="certifiedFaqLineText">
              Certified translations are delivered as a non-editable PDF file and include a signed and stamped English language Certificate of Translation Accuracy, which attests to the thoroughness and accuracy of the translation and the qualifications of the translator. In addition, our certified translations:
              <br/><br/>
              <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Are on company letterhead containing our ATA member number, phone number, and address.<br/><br/>
              <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Include the order number and page number on each page.<br/><br/>
              <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Are formatted similar to, but not exactly like, the source document.<br/><br/>
              <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Are word-for-word translations of the source, which means some phrases might not be ideal, but we cannot interpret the meaning of a phrase, only provide an exact translation per certified translation standards.<br/><br/>
              <span className='bullet'><Check size={windowSize.width <= 1024 ? 10 : 15} color="#18b7bb" /></span>Do not include a copy of your source files in the same PDF unless specifically requested.
              <br/><br/>
              If you order a physical copy we will ship you an unfolded copy of the translation with wet ink signatures in a cardboard USPS envelope printed on 24 lb. paper via a color laser printer. We do not print and ship a copy of your source documents as you will pair our translation with the originals already in your possession.</p>
          </animated.div>
          <animated.div style={faqSprings[4]} className="certifiedFaqLine" onClick={() => toggleLine(4)}>
            <p className="certifiedFaqLineTitle">How are pages counted for certified translation?</p>
            <p className="certifiedFaqLineText">Pages for certified translations are calculated based on the total word count of the document. A page is defined as 250 words or less, including numbers.</p>
          </animated.div>
          <animated.div style={faqSprings[5]} className="certifiedFaqLine" onClick={() => toggleLine(5)}>
            <p className="certifiedFaqLineTitle">How long does certified translation take?</p>
            <p className="certifiedFaqLineText">Standard turnaround starts at 24 hours for up to three pages (750 words), while expedited turnaround reduces the turnaround to 12 hours. Turnaround times are based on the total page count of your order and are calculated during the checkout process.</p>
          </animated.div>
          <animated.div style={faqSprings[6]} className="certifiedFaqLine" onClick={() => toggleLine(6)}>
            <p className="certifiedFaqLineTitle">Can I supply notes such as spellings for names?</p>
            <p className="certifiedFaqLineText">Yes, during checkout we request the preferred spelling of proper nouns.</p>
          </animated.div>
        </div>
        <div className="certifiedGuides">
          <h3 className="certifiedFaqTitle">Translation Guides</h3>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">USCIS Translation Requirements and How to Meet Them</div>
          </div>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">What is a Notarization for a Certified Translation and When is it Required</div>
          </div>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">The Differences Between Certified and Standard Translation</div>
          </div>
          <div className="certifiedFaqLine">
            <div className="certifiedGuidesLineText">eVerbary Translation Process Overview from Start to Finish</div>
          </div>
        </div>
      </div>
      <Footer windowSize={windowSize} />
    </div>
    </>
  );
}

export default Certified;