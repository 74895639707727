import React, { useContext } from 'react';
import { CheckCircle, HelpCircle } from 'react-feather';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { AnimatedButton } from './AnimatedButton';

import './ServicesDisplay.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from '../Context/AuthContext';

const ServicesDisplay = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();
  const { signedIn } = useContext(AuthContext);

  return (
    <div className="servicesDisplayContainer">
      <div className="servicesDisplayHeadingContainer">
        <h2 className="servicesDisplayHeading">Translation Services</h2>
        <p className="servicesDisplaySubheading">We offer certified and standard translation services. Both are performed by the same professional translators, but the style and deliverables differ.</p>
      </div>
      <div className="servicesDisplayTitlesContainer">
        <h3 className="servicesDisplayTitle">Standard Features</h3>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <p className="servicesDisplayTitleInfoText">Translation Style</p>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                This is a critical difference between the two translation types. Ask your receiver if you aren't sure which style of translation you need.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Delivery Format</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                Certified translations can only be edited by us, as we have to re-certify the work afterward.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Professional Translation</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                All translations are performed by professional human translators; we do not use machine translation.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Revisions Included</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                Revisions are included free of charge with all translations. You can request revisions through your order status page.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Formatting Included</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                For certified translations we match the formatting of your source document, but don't replicate the design. Standard translations don't include formatting, but it is optional on a case-by-case basis.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Signed/Stamped Certification</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                Delivered with a signed and stamped Certificate of Translations Accuracy on eVerbary letterhead.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="servicesDisplayTitlesContainer">
        <h3 className="servicesDisplayTitle">Optional Features</h3>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <p className="servicesDisplayTitleInfoText">Expedited Turnaround</p>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                Your order will be prioritized and turnaround time for digital delivery reduced by 50%.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Notarization</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                Stamp and signature authenticating the signer of the translation ceritification. Valid in all 50 states.
              </div>
            </div>
          </div>
        </div>
        <div className="servicesDisplayTitleLine">
          <div className="servicesDisplayTitleInfoContainer">
            <h3 className="servicesDisplayTitleInfoText">Hard Copy</h3>
            <div className="servicesDisplayHelpIcon">
              <HelpCircle color='#666' size={15} style={{ cursor: 'pointer' }}  />
              <div className="servicesDisplayHelpTooltip">
                Original translation with wet ink signatures shipped via USPS Priority Mail or Express Mail.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="servicesDisplayTitlesOffset" />
      <div className="servicesDisplayColumnsContainer">
        <div className="servicesDisplayColumn">
          <div className="servicesDisplayColumnTitleContainer">
            <h2 className="servicesDisplayColumnTitle">Certified Translation</h2>
            <p className="servicesDisplayColumnSub">For official use by universities, courts, and many local, state, and federal governments.</p>
            <p className="servicesDisplayColumnPrice">$24.95 / Page</p>
          </div>
          <div className="servicesDisplayTitlesContainer">
            <p className="servicesDisplayTitle"></p>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">Literal (word-for-word)</p>
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">PDF</p>
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
          </div>
          <div className="servicesDisplayTitlesContainer">
            <p className="servicesDisplayTitle"></p>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
          </div>
          <p className="mobileServicesShortDescription">A literal (word-for-word) translation delivered as a PDF. Revisions, formatting, and a signed/stamped certification are included. Expedited turnaround, notarization, and hard copies are all optional services.</p>
          <div className="servicesDisplayButtonsContainer">
            <AnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.2 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
            <p className="servicesDisplayButtonsMore" onClick={(e) => transitionTo('/certified',e)}>Learn More</p>
          </div>
        </div>
        <div className="servicesDisplayColumn">
          <div className="servicesDisplayColumnTitleContainer">
            <h2 className="servicesDisplayColumnTitle">Standard Translation</h2>
            <p className="servicesDisplayColumnSub">For business or personal use where certification is not required by the receiver.</p>
            <p className="servicesDisplayColumnPrice">$0.10 / Word</p>
          </div>
          <div className="servicesDisplayTitlesContainer">
            <p className="servicesDisplayTitle"></p>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">Interpretative (conveys meaning)</p>
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">DOCX</p>
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">Optional</p>
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">-</p>
              </div>
            </div>
          </div>
          <div className="servicesDisplayTitlesContainer">
            <p className="servicesDisplayTitle"></p>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <CheckCircle color='#666' size={20}  />
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">-</p>
              </div>
            </div>
            <div className="servicesDisplayTitleLine">
              <div className="servicesDisplayValue">
                <p className="servicesDisplayValueText">-</p>
              </div>
            </div>
          </div>
          <p className="mobileServicesShortDescription">A interpretive (conveys meaning) translation delivered as an editable DOCX. Revisions are included. Formatting and expedited turnaround are optional services.</p>
          <div className="servicesDisplayButtonsContainer">
            <AnimatedButton text="Order Now" onClick={(e) => transitionTo('/order/contact',e)} fontSize={windowSize.width > 1024 ? 1.1 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 2.4 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.2)} />
            <p className="servicesDisplayButtonsMore" onClick={(e) => transitionTo('/standard',e)}>Learn More</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { ServicesDisplay };