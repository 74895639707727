import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useSpring, animated } from "react-spring";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import Home from "./Screens/Home";
import Certified from "./Screens/Certified";
import Standard from "./Screens/Standard";
import Interpretations from "./Screens/Interpretations";
// import Documents from './Screens/Documents';
import Languages from "./Screens/Languages";
import UseCases from "./Screens/UseCases";
import FAQ from "./Screens/FAQ";
// import Guides from './Screens/Guides';
import About from "./Screens/About";
// import Jobs from './Screens/Jobs';
import Business from "./Screens/Business";
import OrderLookup from "./Screens/OrderLookup";
import Verify from "./Screens/Verify";
import Contact from "./Screens/Contact";
import Terms from "./Screens/Terms";
import Refunds from "./Screens/Refunds";
import Privacy from "./Screens/Privacy";
import Reviews from "./Screens/Reviews";

// import LanguagesRouter from './Routers/LanguagesRouter';
// import DocumentsRouter from './Routers/DocumentsRouter';
import UseCasesRouter from "./Routers/UseCasesRouter";

// import DifferencesGuide from './Screens/Guides/DifferencesGuide';
// import CountGuide from './Screens/Guides/CountGuide';
// import NotarizationGuide from './Screens/Guides/NotarizationGuide';
// import USCISGuide from './Screens/Guides/USCISGuide';
// import ForeignGuide from './Screens/Guides/ForeignGuide';
// import ApostilleGuide from './Screens/Guides/ApostilleGuide';
// import ProcessGuide from './Screens/Guides/ProcessGuide';
// import GlossaryGuide from './Screens/Guides/GlossaryGuide';
// import TurnaroundGuide from './Screens/Guides/TurnaroundGuide';

import Profile from "./Screens/Account/Profile";
import Quotes from "./Screens/Account/Quotes";
import SingleQuote from "./Screens/Account/SingleQuote";
import Orders from "./Screens/Account/Orders";
import SingleOrder from "./Screens/Account/SingleOrder";
import Shipping from "./Screens/Account/Shipping";

import QuoteContact from "./Screens/Quote/QuoteContact";
import QuoteDetails from "./Screens/Quote/QuoteDetails";

import OrderContact from "./Screens/Order/OrderContact";
import OrderDocuments from "./Screens/Order/OrderDocuments";
import OrderOptions from "./Screens/Order/OrderOptions";
import OrderPayment from "./Screens/Order/OrderPayment";

import Office from "./Screens/Backend/Office";

import { TransitionOverlay } from "./Components/TransitionOverlay";
import { NotificationBox } from "./Components/NotificationBox";

import { TransitionContext } from "./Context/TransitionContext";
import { NotificationContext } from "./Context/NotificationContext";
import { AuthContext } from "./Context/AuthContext";
import { DataContext } from "./Context/DataContext";

import { PrivateRoute } from "./Components/PrivateRoute";
import { HeadedRoute } from "./Components/HeadedRoute";

import "./App.css";
import Members from "./Screens/Team/Members";
import JoinTeam from "./Screens/JoinTeam";
import TeamShipping from "./Screens/Team/Shipping";
import TeamOrders from "./Screens/Team/Orders";
import TeamQuotes from "./Screens/Team/Quotes";
import TeamOptions from "./Screens/Team/Options";
import Password from "./Screens/Account/Password";
import TeamPayments from "./Screens/Team/Payments";
import TeamMakePayment from "./Screens/Team/MakePayment";
// import MainOrderPage from "./Screens/Orders/MainOrderPage";


var firebaseConfig = {
  apiKey: "AIzaSyDME6jFRM9sgZP9lia-q5csaRlLurIm4ac",
  authDomain: "project-everbary.firebaseapp.com",
  databaseURL: "https://project-everbary.firebaseio.com",
  projectId: "project-everbary",
  storageBucket: "project-everbary.appspot.com",
  messagingSenderId: "36002368958",
  appId: "1:36002368958:web:1ca60b8488767203693def",
};

firebase.initializeApp(firebaseConfig);

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const App = () => {
  const [windowSize, setWindowSize] = useState({
    height: window.document.documentElement.clientHeight,
    width: window.document.documentElement.clientWidth,
    orientation:
      window.document.documentElement.clientWidth >
      window.document.documentElement.clientHeight
        ? "landscape"
        : "portrait",
  });

  const [color, setColor] = useState(false);

  const [showPreloader, setShowPreloader] = useState(true);
  const preloaderProps = useSpring({
    opacity: showPreloader ? 1 : 0,
    pointerEvents: showPreloader ? "all" : "none",
  });

  const [webP, setWebP] = useState(null);

  useEffect(() => {
    const checkForWebP = async () => {
      if (!window.self.createImageBitmap) {
        setWebP("png");
        return false;
      }

      const webpData =
        "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
      const blob = await fetch(webpData).then((r) => r.blob());
      const supportsWebP = await createImageBitmap(blob).then(
        () => true,
        () => false
      );

      setWebP(supportsWebP ? "webp" : "png");
    };

    checkForWebP();
  }, []);

  useEffect(() => {
    sleep(1000).then(() => {
      setShowPreloader(false);
    });
  }, []);

  useEffect(() => {
    setWindowSize({
      height: window.document.documentElement.clientHeight,
      width: window.document.documentElement.clientWidth,
      orientation:
        window.document.documentElement.clientWidth >
        window.document.documentElement.clientHeight
          ? "landscape"
          : "portrait",
    });
  }, []);

  const [quote, setQuote] = useState({
    name: "",
    email: "",
    parsed: false,
  });

  const [order, setOrder] = useState({
    orderID: "",
    name: "",
    email: "",
    parsed: false,
  });

  useEffect(() => {
    //localStorage.clear();
    if (localStorage.getItem("order")) {
      let mOrder = JSON.parse(localStorage.getItem("order"));

      if (mOrder.files) {
        mOrder.files = JSON.parse(mOrder.files);
      }

      setOrder({ ...mOrder, parsed: true });
    } else {
      localStorage.setItem("order", JSON.stringify({ ...order, parsed: true }));
      setOrder({ ...order, parsed: true });
    }

    if (localStorage.getItem("quote")) {
      let mQuote = JSON.parse(localStorage.getItem("quote"));

      if (mQuote.files) {
        mQuote.files = JSON.parse(mQuote.files);
      }

      setQuote({ ...mQuote, parsed: true });
    } else {
      localStorage.setItem("quote", JSON.stringify({ ...quote, parsed: true }));
      setQuote({ ...quote, parsed: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [signedIn, setSignedIn] = useState(true);

  const clearSignOut = () => {
    setOrder({ orderID: "", name: "", email: "", parsed: false });
    setQuote({ name: "", email: "", parsed: false });
    setUserData(null);
    localStorage.clear();
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        subscribeUserData(user.uid);
        setSignedIn(true);

        const firestore = firebase.firestore();

        const docRef = firestore.collection("users").doc(user.uid);

        docRef
          .get()
          .then(function (doc) {
            if (!doc.exists) {
              docRef.set(
                {
                  email: user.email,
                },
                { merge: true }
              );
            }
          })
          .catch(function (error) {
            firebase.auth().signOut();
            clearSignOut();
          });
      } else {
        if (typeof unsubscribeUserData.current === "function") {
          unsubscribeUserData.current();
        }
        unsubscribeUserData.current = {};
        setSignedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const [userData, setUserData] = useState(null);

  let unsubscribeUserData = useRef({});

  const subscribeUserData = (userID) => {
    unsubscribeUserData.current = firebase
      .firestore()
      .collection("users")
      .doc(userID)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          setUserData({ ...doc.data(), id: userID });
        }
      });
  };

  const [showTransitionOverlay, setShowTransitionOverlay] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setWindowSize({
        height: window.document.documentElement.clientHeight,
        width: window.document.documentElement.clientWidth,
        orientation:
          window.document.documentElement.clientWidth >
          window.document.documentElement.clientHeight
            ? "landscape"
            : "portrait",
      });
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const setBodyLock = (status) => {
    if (status) {
      document.getElementById("app").style.height = `${windowSize.height}px`;
      document.getElementById("app").style.overflow = `hidden`;
    } else {
      document.getElementById("app").style.height = `auto`;
      document.getElementById("app").style.overflow = `auto`;
    }
  };

  const [notification, setNotification] = useState({
    show: false,
    title: "Test Alert",
    text: "This is a test alert to see how it works.",
    type: "info",
  });

  const showNotification = (title, text, type) => {
    setNotification({ show: true, title: title, text: text, type: type });

    sleep(3500).then(() => {
      setNotification({ show: false, title: title, text: text, type: type });
    });
  };

  return (
    <TransitionContext.Provider
      value={{
        setShowTransitionOverlay: setShowTransitionOverlay,
        setBodyLock: setBodyLock,
        color: color,
        setColor: setColor,
        webP: webP,
      }}
    >
      <NotificationContext.Provider
        value={{ showNotification: showNotification }}
      >
        <AuthContext.Provider
          value={{
            signedIn: signedIn,
            setSignedIn: setSignedIn,
            clearSignOut: clearSignOut,
          }}
        >
          <DataContext.Provider
            value={{
              quote: quote,
              setQuote: setQuote,
              order: order,
              setOrder: setOrder,
              userData: userData,
            }}
          >
            <PayPalScriptProvider
              options={{
                "client-id":
                  "Ac12crJpGvNHc4wOWVXm_yKEcwiCgR1GZsGUMcZhhfvkWuxocLdQjfR9Y1Lk2whqhh9k99hkOiTkah2P",
              }}
            >
              <div id="app" className="appContainer">
                <animated.div
                  style={preloaderProps}
                  className="preloaderOverlay"
                />

                <TransitionOverlay show={showTransitionOverlay} />

                <NotificationBox
                  windowSize={windowSize}
                  show={notification.show}
                  title={notification.title}
                  text={notification.text}
                  type={notification.type}
                />

                <Router>
                  <Switch>
                    {userData &&
                      (userData.role === "owner" ||
                        userData.role === "Support" ||
                        userData.role === "Translator") && (
                        <PrivateRoute path="/office">
                          <Office windowSize={windowSize} />
                        </PrivateRoute>
                      )}
                    <HeadedRoute path="/order/payment">
                      <OrderPayment windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/order/options">
                      <OrderOptions windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/order/documents">
                      <OrderDocuments windowSize={windowSize} />
                    </HeadedRoute>


                    <HeadedRoute path="/order/contact">
                      <OrderContact windowSize={windowSize} />
                      {/* <MainOrderPage /> */}
                    </HeadedRoute>


                    <HeadedRoute path="/quote/details">
                      <QuoteDetails windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/quote/contact">
                      <QuoteContact windowSize={windowSize} />
                    </HeadedRoute>
                    <PrivateRoute path="/account/shipping">
                      <Shipping windowSize={windowSize} />
                    </PrivateRoute>
                    <HeadedRoute path="/account/quote/:quoteID">
                      <SingleQuote windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/account/order/:orderID">
                      <SingleOrder windowSize={windowSize} />
                    </HeadedRoute>
                    <PrivateRoute path="/account/orders">
                      <Orders windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/account/quotes">
                      <Quotes windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/account/profile">
                      <Profile windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/account/password">
                      <Password windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/team/members">
                      <Members windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/team/shipping">
                      <TeamShipping windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/team/orders">
                      <TeamOrders windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/team/quotes">
                      <TeamQuotes windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/team/options">
                      <TeamOptions windowSize={windowSize} />
                    </PrivateRoute>
                    <PrivateRoute path="/team/payments">
                      <TeamPayments windowSize={windowSize} />
                    </PrivateRoute>
                    <HeadedRoute path="/team/make-payment/:teamInvoiceID">
                      <TeamMakePayment windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/join-team">
                      <JoinTeam windowSize={windowSize} />
                    </HeadedRoute>
                    {/* <HeadedRoute path="/guides">
                      <Guides windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/differences">
                      <DifferencesGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/count">
                      <CountGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/notarization">
                      <NotarizationGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/uscis">
                      <USCISGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/foreign">
                      <ForeignGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/apostille">
                      <ApostilleGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/process">
                      <ProcessGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/glossary">
                      <GlossaryGuide windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/guide/turnaround">
                      <TurnaroundGuide windowSize={windowSize} />
                    </HeadedRoute> */}
                    <HeadedRoute path="/terms">
                      <Terms windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/refunds">
                      <Refunds windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/privacy">
                      <Privacy windowSize={windowSize} />
                    </HeadedRoute>
                    {/* <HeadedRoute path="/jobs">
                      <Jobs windowSize={windowSize} />
                    </HeadedRoute> */}
                    <HeadedRoute path="/about">
                      <About windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/business">
                      <Business windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/contact">
                      <Contact windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/order-lookup">
                      <OrderLookup windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/verify">
                      <Verify windowSize={windowSize} />
                    </HeadedRoute>

                    {/* <HeadedRoute path="/sign-in">
                      <SignIn windowSize={windowSize} />
                    </HeadedRoute> */}


                    {/* <HeadedRoute path="/forgot">
                      <ForgotPassword windowSize={windowSize} />
                    </HeadedRoute> */}

                    {/* <HeadedRoute path="/create-account">
                      <CreateAccount windowSize={windowSize} />
                    </HeadedRoute> */}

                    <HeadedRoute path="/faq">
                      <FAQ windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/reviews">
                      <Reviews windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/use-cases">
                      <UseCases windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/use-case">
                      <UseCasesRouter windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/languages">
                      <Languages windowSize={windowSize} />
                    </HeadedRoute>
                    {/* <HeadedRoute path="/language">
                      <LanguagesRouter windowSize={windowSize} />
                    </HeadedRoute> */}
                    {/* <HeadedRoute path="/documents">
                      <Documents windowSize={windowSize} />
                    </HeadedRoute> */}
                    {/* <HeadedRoute path="/document">
                      <DocumentsRouter windowSize={windowSize} />
                    </HeadedRoute> */}
                    <HeadedRoute path="/standard">
                      <Standard windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/interpretations">
                      <Interpretations windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/certified">
                      <Certified windowSize={windowSize} />
                    </HeadedRoute>
                    <HeadedRoute path="/">
                      <Home windowSize={windowSize} />
                    </HeadedRoute>
                  </Switch>
                </Router>
              </div>
            </PayPalScriptProvider>
          </DataContext.Provider>
        </AuthContext.Provider>
      </NotificationContext.Provider>
    </TransitionContext.Provider>
  );
};

export default App;
