import React, { useContext } from 'react';

import { useScreenTransition } from '../Hooks/useScreenTransition';

import { AnimatedButton } from './AnimatedButton';

import './Footer.css';
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { AuthContext } from '../Context/AuthContext';

const Footer = ({ windowSize }) => {

  const { transitionTo } = useScreenTransition();
  const { signedIn } = useContext(AuthContext);

  return (
    <div className="footerContainer">
      <div className="footerReadyContainer">
        <h3 className="footerReadyText">Ready to get your translations started?</h3>
        <AnimatedButton text="Order Now" onClick={(e) => transitionTo(`${signedIn && firebase.auth().currentUser ? '/order/documents' : '/order/contact'}`,e)} fontSize={windowSize.width > 1024 ? 1.3 : windowSize.width > 767 ? (windowSize.orientation === 'portrait' ? 1.8 : 1.5) : (windowSize.orientation === 'portrait' ? 5 : 1.8)} />
      </div>
      <div className="footerSeparator" />
      <div className="footerMainContainer">
        <div className="footerMainSecondary">
          <img src={require('../assets/smallLogo.png')} alt="Small logo eVerbary" className="footerMainSecondaryLogo" />
          <p className="footerSecondaryText">2023 © eVerbary</p>
          <p className="footerSecondaryText"><span className="hoveringTextLink" onClick={(e) => transitionTo('/terms',e)}>Terms</span> - <span className="hoveringTextLink" onClick={(e) => transitionTo('/refunds',e)}>Refunds</span> - <span className="hoveringTextLink" onClick={(e) => transitionTo('/privacy',e)}>Privacy</span></p>
        </div>
        <div className="footerMainListContainer">
          <div className="footerMainListColumn">
            <p className="footerMainListColumnTitle">Services</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/certified',e)}>Certified Translation</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/standard',e)}>Standard Translation</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/interpretations',e)}>Interpretations</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/business',e)}>For Businesses</p>
          </div>
          <div className="footerMainListColumn nonMobileColumn">
            <p className="footerMainListColumnTitle">Resources</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/languages',e)}>Languages</p>
            {/* <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/documents',e)}>Documents</p> */}
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/use-cases',e)}>Use Cases</p>
          </div>
          <div className="footerMainListColumn">
            <p className="footerMainListColumnTitle">Help</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/faq',e)}>FAQ</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/contact',e)}>Contact</p>
            {/* <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/guides',e)}>Guides</p> */}
          </div>
          <div className="footerMainListColumn">
            <p className="footerMainListColumnTitle">Company</p>
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/about',e)}>About</p>
            {/* <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/jobs',e)}>Jobs</p> */}
            <p className="footerMainListColumnLink" onClick={(e) => transitionTo('/reviews',e)}>Reviews</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Footer };